import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useAdsListSales() {
  // Use toast
  const toast = useToast();

  const refAdListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id", sortable: true },
    { key: "flow_origin", label: "flow", sortable: true },
    { key: "zip", sortable: true },
    { key: "type_parent", sortable: true },
    { key: "price", sortable: true, tdClass: "limit-col-width-10" },
    {
      key: "price_excl_fees",
      label: "price - fees =",
      sortable: true,
      tdClass: "limit-col-width-10",
    },
    { key: "payer_fees", sortable: true },
    { key: "percentage_buyer_fees", label: "% fees", sortable: true },
    { key: "dpe_value_consumption", label: "dpe", sortable: true },
    { key: "dpe_value_gaz", label: "ges", sortable: true },
    { key: "latitude", label: "lat", sortable: true },
    { key: "longitude", label: "lng", sortable: true },
    { key: "enabled", sortable: true },
    { key: "integration_date", sortable: true },
  ];

  const flowOptions = ref([]);

  const typeParentOptions = [
    { label: "apartment", value: "apartment" },
    { label: "house", value: "house" },
    { label: "parking", value: "parking" },
    { label: "local", value: "local" },
    { label: "field", value: "field" },
    { label: "other", value: "other" },
  ];

  const idQuery = ref(undefined);
  const zipQuery = ref(undefined);
  const perPage = ref(15);
  const totalAds = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [15, 30, 60, 120];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const minIntegrationDate = ref(undefined);
  const maxIntegrationDate = ref(undefined);
  const isEnabled = ref(false);
  const flowFilter = ref(null);
  const typeParentFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refAdListTable.value
      ? refAdListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAds.value,
    };
  });

  const refetchData = () => {
    refAdListTable.value.refresh();
  };

  watch(
    [
      idQuery,
      zipQuery,
      currentPage,
      perPage,
      searchQuery,
      isEnabled,
      minIntegrationDate,
      maxIntegrationDate,
      flowFilter,
      typeParentFilter,
    ],
    () => {
      refetchData();
    }
  );

  const fetchFlowsOrigins = () => {
    let queryParams = {
      typeOffers: "ANCIEN",
    };

    store
      .dispatch("app-ads/fetchFlowsOrigins", queryParams)
      .then((response) => {
        flowOptions.value = response.data.flows_origins;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching flows origins' list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const fetchAds = (ctx, callback) => {
    let queryParams = {
      idQuery: idQuery.value,
      zipQuery: zipQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      minIntegrationDate: minIntegrationDate.value,
      maxIntegrationDate: maxIntegrationDate.value,
      enabled: isEnabled.value,
      service: "V",
    };

    if (flowFilter.value != null) queryParams.flow = flowFilter.value;
    if (typeParentFilter.value != null)
      queryParams.type_parent = typeParentFilter.value;

    store
      .dispatch("app-ads/fetchAds", queryParams)
      .then((response) => {
        const ads = response.data.ads;
        totalAds.value = response.data.count;
        callback(ads);
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching ads' list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveAdTypeParentVariant = (typeParent) => {
    if (typeParent === "apartment") return "primary";
    if (typeParent === "field") return "secondary";
    if (typeParent === "local") return "warning";
    if (typeParent === "house") return "success";
    if (typeParent === "parking") return "info";
    if (typeParent === "other") return "danger";
    return "primary";
  };

  return {
    // Options
    flowOptions,
    typeParentOptions,

    fetchAds,
    fetchFlowsOrigins,
    tableColumns,
    idQuery,
    zipQuery,
    perPage,
    currentPage,
    totalAds,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    minIntegrationDate,
    maxIntegrationDate,
    isEnabled,
    refAdListTable,

    resolveAdTypeParentVariant,
    refetchData,

    // Extra Filters
    flowFilter,
    typeParentFilter,
  };
}
